'use client'

import React from 'react'
import { ButtonKind, ButtonWidth, Button } from '../button'
import useBreakpoint from '~/hooks/useBreakpoint'
import { FooterProps } from './footer.types'

export const Footer: React.FC<FooterProps> = ({ sucessButton, cancelButton, draftButton, children }) => {
  const { lg } = useBreakpoint()

  // TODO: Find a better way to handle the footer top margin, maybe leave it to the parent layout content.
  return (
    <footer className='sticky bottom-0 left-0 right-0 z-50 flex w-full justify-center border-t border-bg-neutral-30 bg-neutral-00 lg:h-16'>
      <div className='flex max-w-[1112px] flex-1 flex-col gap-2 px-4 py-3 lg:flex-row-reverse lg:justify-start'>
        {children ? (
          children
        ) : (
          <>
            {!!sucessButton && (
              <Button
                {...sucessButton}
                width={lg ? ButtonWidth.auto : ButtonWidth.full}
              />
            )}
            {!!draftButton && (
              <Button
                {...draftButton}
                kind={ButtonKind.secondary}
                isStandalone={false}
                width={lg ? ButtonWidth.auto : ButtonWidth.full}
              />
            )}
            {!!cancelButton && (
              <Button
                {...cancelButton}
                kind={ButtonKind.secondary}
                isStandalone={false}
                width={lg ? ButtonWidth.auto : ButtonWidth.full}
              />
            )}
          </>
        )}
      </div>
    </footer>
  )
}
