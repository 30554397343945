'use client'

import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import '@scalis/components/styles/globals.css'
import useBreakpoint from '~/hooks/useBreakpoint'
import { ButtonProps } from '~/scalis-components/core/button'
import { Footer } from '~/scalis-components/core/footer'
import NavbarTemplate from '~/scalis-components/core/navbar/navbar-template/navbar-template'
import { Wizard } from '~/scalis-components/core/wizard'
import { Step } from '~/scalis-components/core/wizard/wizard.types'

interface FooterContextProps {
  footerContent: React.ReactNode | null
  setFooterContent: (content: React.ReactNode | null) => void
  setSuccessButton: (props: ButtonProps | null) => void
  setCancelButton: (props: ButtonProps | null) => void
}
const FooterContext = createContext({} as FooterContextProps)
export const FooterProvider = ({ children, hideFooter }: PropsWithChildren & { hideFooter?: boolean }) => {
  const [footerContent, setFooterContent] = useState<React.ReactNode>(null)

  const [successButton, setSuccessButton] = useState<ButtonProps | null>(null)

  const [cancelButton, setCancelButton] = useState<ButtonProps | null>(null)

  return (
    <FooterContext.Provider
      value={{
        footerContent,
        setFooterContent,
        setCancelButton,
        setSuccessButton,
      }}
    >
      <div className='flex min-h-screen flex-col bg-brand-primary-light-00'>
        {children}

        {!hideFooter && (
          <Footer
            cancelButton={cancelButton}
            sucessButton={successButton}
          >
            {footerContent}
          </Footer>
        )}
      </div>
    </FooterContext.Provider>
  )
}

export const useFooter = () => useContext(FooterContext)

const ApplicationLayout = ({
  children,
  steps,
  hideFooter,
  showCompanyName,
}: {
  children: React.ReactNode
  steps?: Step[]
  hideFooter?: boolean
  showCompanyName?: boolean
}) => {
  const { md } = useBreakpoint()
  const [visibleSteps, setVisibleSteps] = useState<Step[]>(steps ?? [])

  useEffect(() => {
    if (steps) {
      setVisibleSteps(steps.filter((step) => !step.isHidden))
    }
  }, [steps])

  return (
    <FooterProvider hideFooter={hideFooter}>
      <NavbarTemplate
        isLogoOnly={true}
        showCompanyName={showCompanyName}
      />

      <div className='flex flex-1 flex-col items-center bg-brand-primary-light-00 pb-2'>
        <div className='flex w-full max-w-7xl flex-1 flex-col'>
          {visibleSteps.length > 0 && (
            <div className='mt-6 px-9'>
              <Wizard
                steps={visibleSteps}
                isMobile={!md}
              />
            </div>
          )}

          <main className='flex w-full flex-1 items-center justify-center '>{children}</main>
        </div>
      </div>
    </FooterProvider>
  )
}

export default ApplicationLayout
